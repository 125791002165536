<template>
  <div style="height: 100%" class="d-flex">
    <Hierarchy
      :hierarchy="services"
      context="service"
      :hide-tooltip="hideTooltip"
      :tooltip-delay="tooltipDelay"
      item-title="name"
      item-children="services"
      style="max-height: 100%"
      @final="handleHierarchyFinal"
      :disabled="disabled"
    ></Hierarchy>
  </div>
</template>

<script>
import Hierarchy from './Hierarchy.vue'
import serviceTypesCategories from "@/services/serviceTypesCategories/serviceTypesCategories.service.js";
import billsService from "@/services/bills/bills.service.js"
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "ServiceSelector",
  components: { 
    Hierarchy,
  },
  mixins: [dateUtils],
  data: function() {
    return {
      hierarchy: [ ],
      services: [ ],
      localLoading: false,
      colorize: false,
      localValue: undefined
    }
  },
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showPhaseOnHover: {
      type: Boolean,
      default: false
    },
    showPrices: {
      type: Boolean,
      default: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    showFrequentServices: {
      type: Boolean,
      default: false
    },
    hideTooltip: {
      type: Boolean,
      default: false
    },
    tooltipDelay: {
      type: String | Number,
      default: 0
    },
    customer: {
      type: Object,
      validator: (value) => {
        return !!value.id
      }
    }
  },
  mounted: function() {
    this.loadServices()
    if(!!this.customer) {
      this.loadServices()
    }
  },
  methods: {
    loadServices() {
      this.localLoading = true

      let promises = [
        this.fetchAllServices()
      ]

      if(this.showFrequentServices) {
        promises.push(this.fetchFrequentCustomerServices())
        promises.push(this.fetchFrequentSaloonServices())
      }

      Promise.all(promises).then((results) => {
        let finalServices = []
        for(let i = 0; i < results.length; i += 1) {
          finalServices = [...finalServices, ...results[i]]
        }

        this.services = [...finalServices]
        this.localLoading = false
      })
    },
    async fetchAllServices() {
      return await serviceTypesCategories.list(undefined, undefined, {isBar: false}).then((results) => {
        for(let i = 0; i < results.rows.length; i += 1) {
          results.rows[i].services = results.rows[i].serviceTypes
        }
        
        return results.rows
      })
    },
    async fetchFrequentCustomerServices() {
      if(!this.customer) return []

      return await billsService.frequentCustomerServicesCached({ customer: this.customer }).then((results) => {
        return [
          {
            id: 'frequent',
            name: '+ FREQUENTI DEL CLIENTE',
            services: results
          }
        ]
      })
    },
    async fetchFrequentSaloonServices() {
      return await billsService.frequentServicesCached().then((results) => {
        return [
          {
            id: 'saloonFrequent',
            name: '+ FREQUENTI SALONE',
            services: results
          }
        ]
      })
    },
    handleHierarchyFinal(item) {
      this.$emit('click', item)
      this.$emit('input', item)
    }
  },
  computed: {
    servicesToShow() {
      return this.services
    }
  },
  watch: {
    customer() {
      this.loadServices()
    }
  },
  settings: {
    colorizeServiceSelector: {
      bind: 'colorize'
    }
  }
}
</script>

<style>

</style>